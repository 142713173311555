import React, { Component } from 'react';
import { ListGroup, Button, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class Question extends Component {
  state = { selectedAnswerId: null };

  handleOptionChange = (event) => {
    this.setState({
      selectedAnswerId: parseInt(event.target.value, 10)
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // onAnswerSubmit jest przekazywana z komponentu Exam
    if (this.state.selectedAnswerId) {
      this.props.onAnswerSubmit(this.state.selectedAnswerId, this.props.questionId);
    }
  };

  render() {
    const { question, answers, t } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <h3>{question}</h3>
        <ListGroup className="mb-3">
          {answers.map(answer => (
            <ListGroup.Item key={answer.id} as="label" className="d-flex justify-content-between align-items-center">
              <Form.Check 
                type="radio"
                name="answers"
                value={answer.id}
                id={`answer-${answer.id}`}
                checked={this.state.selectedAnswerId === answer.id}
                onChange={this.handleOptionChange}
                label={answer.answer}
                className="mb-2"
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Button variant="danger" type="submit" className="mt-3">{t('submit_next_question')}</Button>
      </Form>
    );
  }
}

export default withTranslation()(Question);
