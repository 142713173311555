import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = ({ isLoading }) => (
  isLoading ? (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  ) : null
);

export default LoadingSpinner;
