import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CourseContent = ({ pdf, onExamStart, t }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(1);
  };

  const onDocumentLoadError = (error) => {
    console.error("Błąd ładowania dokumentu PDF:", error);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, numPages));
  };

  const updateContainerDimensions = () => {
    if (containerRef.current) {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const navigationHeight = document.querySelector('.fixed-bottom').clientHeight;
      const padding = 45; // 60px (domyślny pasek) - 15px (padding Bootstrap)
      setContainerDimensions({
        width: containerRef.current.clientWidth,
        height: windowHeight - navigationHeight - padding,
      });
    }
  };

  useEffect(() => {
    updateContainerDimensions();
    window.addEventListener('resize', updateContainerDimensions);

    return () => window.removeEventListener('resize', updateContainerDimensions);
  }, []);

  if (!pdf || !pdf.url) {
    return <div>{t('pdf_not_available')}</div>;
  }

  return (
    <Container fluid className="CourseContent" style={{ paddingBottom: '45px' }}>
      <Row>
        <Col md={12} ref={containerRef} className="d-flex justify-content-center">
          <div style={{ height: `${containerDimensions.height}px`, width: `${containerDimensions.width}px`, overflow: 'hidden' }}>
            <Document
              file={pdf.url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              loading={<div>{t('loading')}</div>}
              error={<div>{t('error_loading_pdf')}</div>}
            >
              <Page
                pageNumber={currentPage}
                width={containerDimensions.width}
              />
            </Document>
          </div>
        </Col>
      </Row>
      <Row className="fixed-bottom bg-light">
        <Col md={3} className="d-flex align-items-center">
          <Button variant="danger" onClick={handlePreviousPage} disabled={currentPage <= 1}>
            {t('previous_slide')}
          </Button>
        </Col>
        <Col md={6} className="text-center d-flex align-items-center justify-content-center">
          {numPages && (
            <span>
              {t('page')} {currentPage} {t('of')} {numPages}
            </span>
          )}
        </Col>
        <Col md={3} className="d-flex align-items-center justify-content-end">
          {currentPage < numPages && (
            <Button variant="danger" onClick={handleNextPage}>{t('next_slide')}</Button>
          )}
          {currentPage === numPages && (
            <Button variant="danger" onClick={onExamStart}>
              {t('start_exam')}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation()(CourseContent);
