import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Question from './Question';
import LoadingSpinner from './LoadingSpinner';

const Exam = ({ t, questions, trainingId, userId, onRetakeCourse }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState(null);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isPassed, setIsPassed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAnswerSubmit = (answerId, questionId) => {
    setAnswers({ ...answers, [questionId]: answerId });
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleRetakeExam = () => {
    setCurrentQuestionIndex(0);
    setShowThankYouModal(false);
    onRetakeCourse();
  };

  const handleFinishExam = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}submit-answers/${trainingId}`, {
        training: trainingId,
        userId: userId,
        answers: answers,
      });
  
      const { error, message, data } = response.data;
  
      setShowThankYouModal(true);
  
      // Przekazujemy cały obiekt data do tłumaczenia
      setModalMessage(
        t(message, data)
      );
  
      setIsPassed(!error);
    } catch (err) {
      setError(err);
      setModalMessage(err.response?.data?.error || 'Unknown error');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleThankYouModalClose = () => {
    window.location.reload();
  };

  const handleCloseErrorModal = () => {
    setError(null);
  };

  return (
    <div className="col">
      <LoadingSpinner isLoading={isLoading} />
      {currentQuestionIndex < questions.length ? (
        <Question
          key={questions[currentQuestionIndex].id}
          question={questions[currentQuestionIndex].question}
          answers={questions[currentQuestionIndex].answers}
          onAnswerSubmit={handleAnswerSubmit}
          questionId={questions[currentQuestionIndex].id}
        />
      ) : (
        !showThankYouModal && (
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>{t('no_more_questions_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{t('no_more_questions_description')}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleFinishExam}>
                {t('finish_exam')}
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        )
      )}

      <Modal show={showThankYouModal} onHide={handleThankYouModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('thank_you_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          {isPassed ? (
            <Button variant="danger" onClick={handleThankYouModalClose}>
              OK
            </Button>
          ) : (
            <Button variant="dark" onClick={handleRetakeExam}>
              {t('retake_exam')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {error && (
        <Modal show={true} onHide={handleCloseErrorModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('error')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{error.message || 'An unexpected error occurred.'}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseErrorModal}>
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default withTranslation()(Exam);
