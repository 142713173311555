import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import CourseContent from './CourseContent';
import Exam from './Exam';
import { Modal, Button } from 'react-bootstrap';

class Training extends Component {
  state = {
    courseData: null,
    isLoading: true,
    error: null,
    isExamStarted: false,
    showModal: false
  };

  componentDidMount() {
    this.fetchCourseData();
  }

  fetchCourseData = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    axios.get(`${apiUrl}${this.props.trainingId}`)
      .then(response => {
        this.setState({ courseData: response.data.training, isLoading: false });
      })
      .catch(error => {
        this.setState({ error: error.message, isLoading: false, showModal: true });
      });
  };

  handleExamStart = () => {
    this.setState({ isExamStarted: true });
  };

  handleRetakeCourse = () => {
    this.fetchCourseData();
    this.setState({ isExamStarted: false });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { t } = this.props;
    const { courseData, isLoading, error, isExamStarted, showModal } = this.state;

    if (isLoading) {
      return <div>{t('loading_course_data')}</div>;
    }

    return (
      <div className="Training d-flex justify-content-center align-items-center">
        {courseData && (
          <div className="w-100">
            {!isExamStarted ? (
              <CourseContent pdf={courseData.pdf} onExamStart={this.handleExamStart} /> 
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <Exam
                  questions={courseData.questions}
                  trainingId={courseData.id}
                  userId={this.props.userId}
                  requiredQuestionsCount={courseData.requiredQuestionsCount}
                  requiredPointsPercentage={courseData.requiredPointsPercentage}
                  onRetakeCourse={this.handleRetakeCourse}
                />
              </div>
            )}
          </div>
        )}

        <Modal show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('error')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.handleCloseModal}>
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(Training);
